import React from 'react';
import { Button, FormControl, Modal, ProgressBar } from 'react-bootstrap';
import { postCreateSitestory } from '../../../utils/api/functions/sitestories';



const CreateStoryFileUploadModal = ({show, onHide, handleUploadFile, isSubmitting=false, progress=0}) => {
    const [file, setFile] = React.useState(null); 

    const handleFileChange = (e) => {
        setFile(e.target.files[0]); 
    }
    const handleSubmit = () => {
        handleUploadFile(file); 
    }

    return(
        <Modal
            show={show}
            size="lg"
            centered
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title>
                    Upload Story
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormControl type="file" onChange={handleFileChange}/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="danger">Cancel</Button>
                <Button onClick={handleSubmit} variant="primary" disabled={isSubmitting}>Upload</Button>
                <div
                    style={{
                        display: 'flex', 
                        width: "100%"
                    }}
                >
                    {
                        progress > 0 &&
                        <ProgressBar now={progress} label={`${progress}%`}
                            style={{
                                width: "100%"
                            }}
                        />
                    }
                </div>
                
            </Modal.Footer>
        </Modal>
    )

}

const CreateStory = () => {

    const [showCreate, setShowCreate] = React.useState(false); 

    const handleShowCreate = () => {
        setShowCreate(true); 
    }
    const handleHideCreate = () => {
        setShowCreate(false); 
    }
    const handleUploadFile = async(file) => {

        const formData = new FormData(); 
        formData.append("filedata", file); 
        console.log("Uploading file: ", file)
        const res = await postCreateSitestory(formData); 
        console.log("Create story: ", res); 
    }


    return(
        <div>
            <Button
                onClick={handleShowCreate}
            >
            Create Story
            </Button>
            <CreateStoryFileUploadModal 
                show={showCreate}
                onHide={handleHideCreate}
                handleUploadFile={handleUploadFile}
            />
        </div>
        
    )
}


export default CreateStory; 
export { CreateStoryFileUploadModal };

