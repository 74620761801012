import { Formik } from "formik";
import { useToast } from "rc-toastr";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TailSpin } from "react-loading-icons";
import { useNavigate } from "react-router";
import {
  postCreateCampaign,
  useGetOwnersAds,
  useGetPackages,
} from "../../utils/api/functions/communityAds";

const Package = ({ title, description, price, onClick }) => {
  return (
    <div className="advertisement-package">
      <div className="advertisement-package-content">
        <div className="advertisement-package-header">{title}</div>
        <div className="advertisement-package-description">
          Custom Ad, Album, Blog, Event, Group, Page, Video <br />
          {description}
        </div>
        <div className="advertisement-package-price">${price}</div>
        <Button className="advertisement-package-create-btn" variant="primary" onClick={onClick}>
          {" "}
          Create Ad{" "}
        </Button>
      </div>
    </div>
  );
};

const ChoosePackage = ({ handleSelect }) => {
  const { data, isLoading } = useGetPackages({});
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (data && data.body && data.body.packages) {
      setPackages(data.body.packages);
    }
  }, [data]);

  if (isLoading)
    return (
      <div className="loading-icon-wrapper">
        <TailSpin stroke="#00BCD4" fill="#00BCD4" />
      </div>
    );

  return (
    <div className="advertise-create-choose-package">
      {packages.map((val, index) => {
        return (
          <Package
            key={index}
            title={val.title}
            description={val.description}
            price={val.price}
            onClick={() => {
              handleSelect(val);
            }}
          />
        );
      })}
    </div>
  );
};

const CampaignSelect = (props) => {
  const { data } = useGetOwnersAds({});
  const [campaigns, setCampaigns] = useState([]);
  useEffect(() => {
    if (data && data.body && data.body.campaigns) {
      setCampaigns(data.body.campaigns);
    }
  }, [data]);

  return (
    <Form.Select {...props}>
      <option value="new">Create New Campaign</option>
      {campaigns.map((val) => {
        return <option value={val.id}>{val.name}</option>;
      })}
    </Form.Select>
  );
};

const CreateAdForm = ({ package_data }) => {
  const { data } = useGetOwnersAds({});
  const [imagePreview, setImagePreview] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");

  const {toast} = useToast(); 
  const navigate = useNavigate(); 

  useEffect(() => {}, [data]);

  const handleImage = (e) => {
    const file = e.target.files[0];
    setImagePreview(file);
  };

  useEffect(() => {
    if (!imagePreview) return;

    const objectUrl = URL.createObjectURL(imagePreview);
    setImagePreviewUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [imagePreview]);

  const handleSubmit = async (values, {setSubmitting}) => {
    console.log("values", values); 
    console.log("Package data", package_data); 
    values.id = package_data.package_id;
    const res = await postCreateCampaign(values); 
    if(res.status_code === 200) {
      toast.success("Ad created successfully"); 
      navigate("/advertise/campaigns")
    }
    else {
      toast.error("Error creating ad"); 
    }
  }; 

  return (
    <div className="advertise-create-form">
      <Formik
        initialValues={{
          campaign: "new",
          campaign_name: "",
          content_type: "Album",
          ad_title: "",
          ad_description: "",
          ad_image: "",
          ad_link: "",
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="campaign" className="mb-3">
                    <div className="advertise-create-form-header">
                      <span>Ad Type: </span>
                      {package_data.title}
                    </div>
                    <Form.Label>Select Campaign</Form.Label>
                    <CampaignSelect onChange={handleChange} />
                  </Form.Group>

                  {values && values.campaign === "new" ? (
                    <Form.Group controlId="campaign_name" className="mb-3">
                      <Form.Label>Campaign Name</Form.Label>
                      <Form.Control type="text" onChange={handleChange} />
                    </Form.Group>
                  ) : null}

                  <Form.Group controlId="content_type" className="mb-3">
                    <Form.Label>Content Type</Form.Label>
                    <Form.Select onChange={handleChange}>
                      <option>Album</option>
                      <option>Blog</option>
                      <option>Event</option>
                      <option>Group</option>
                      <option>Page</option>
                      <option>Video</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="ad_title" className="mb-3">
                    <Form.Label>Ad Title</Form.Label>
                    <Form.Control type="text" onChange={handleChange} />
                  </Form.Group>
                  <Form.Group controlId="ad_body" className="mb-3">
                    <Form.Label>Ad Body Text</Form.Label>
                    <Form.Control type="text" onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="ad_image" className="mb-3">
                    <Form.Label>Ad Image</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        handleChange(e);
                        handleImage(e);
                      }}
                    />
                  </Form.Group>
                  <div className="advertise-create-form-image-preview">
                    <img src={imagePreviewUrl} />
                  </div>
                </Col>
              </Row>
              <Form.Group>
                <Button variant="primary" type="submit" onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Create Ad
                </Button>
              </Form.Group>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const CreateAd = () => {
  const [step, setStep] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState({});

  useEffect(() => {}, [step]);

  const handleChoosePackage = async (package_data) => {
    await setSelectedPackage(() => package_data);
    setStep(1);
  };

  return (
    <div className="advertise-content-box">
      <div className="advertise-content-box-header">Advertise On XAPiT</div>
      <div className="box-content">
        {step === 0 && <ChoosePackage handleSelect={handleChoosePackage} />}
        {step === 1 && <CreateAdForm package_data={selectedPackage} />}
      </div>
    </div>
  );
};

export default CreateAd;
