import React, { useEffect, useState } from "react";
import { useGetAds } from "../../utils/api/functions/communityAds";
import { map_type_to_link } from "../../utils/mapping";

const AdsWidget = () => {
  const [ads, setAds] = useState([]);
  const { data } = useGetAds({});

  useEffect(() => {

    if (data && data.body && data.body.advertisements) {

      // If there is more than one ad, randomly select two
      if(data.body.advertisements.length > 1) { 
        let randomAd1 = Math.floor(Math.random() * data.body.advertisements.length);
        let randomAd2 = Math.floor(Math.random() * data.body.advertisements.length);
        while(randomAd1 === randomAd2) {
          randomAd2 = Math.floor(Math.random() * data.body.advertisements.length);
        }
        setAds(() => [data.body.advertisements[randomAd1], data.body.advertisements[randomAd2]]);
      }
      // Else just set the response data
      else {
        setAds(() => data.body.advertisements);
      }
      
    }
  }, [data]);

  return (
    <div className="ads-widget-container">
      {ads.map((ad, index) => (
        <div className="ads-widget-item" key={`ads-widget-item-${index}`}>
          <a href={map_type_to_link(ad.resource_type, ad.resource_id)}></a>
          <div className="ads-widget-item-title">{ad.cads_title}</div>

          <img className="ads-widget-item-image" src={ad.image} />
          <div className="ads-widget-item-description">{ad.cads_body}</div>
        </div>
      ))}
    </div>
  );
};

export default AdsWidget;
