import {
  faBars,
  faComment,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "rc-toastr";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Bars } from "react-loading-icons";
import { useParams } from "react-router";
import { postDeleteAlbumPhoto, useGetAlbum } from "../../utils/api/functions/photosAlbums";
import { postDeletePageAlbumPhoto, useGetSitePage, useGetSitePageAlbumPhotos } from "../../utils/api/functions/sitepages";
import VideoDisplay from "../videos/VideoDisplay";
import ConfirmationModal from "../widgets/ConfirmationModal";
import { ReportModal } from "../widgets/ReportModal";
import ShareUserContent from "../widgets/ShareUserContent";


const PhotoItem = ({
  photo_data,
  handleShowPhoto = () => {}, 
  updatePhotoData = () => {},
  item_type="photo",
  isOwnerPredetermined=false
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const {id} = useParams(); 
  const {toast} = useToast(); 
  const ref = useRef();
  
  const [showReport, setShowReport] = useState(false); 
  console.log("Photo_data", photo_data)

  let isOwner = false; 
  if(isOwnerPredetermined === true){
    isOwner = true; 
  }
  else {
    isOwner = photo_data.owner_id == localStorage.getItem("user_id");
  }
    
  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleDownload = () => {
    setShowMenu(false);
  };

  const handleShare = () => {
    setShowMenu(false);
    setShowShare(true);
  };
  const handleHideShare = () => {
    setShowShare(false);
  };

  const handleShowReport = () => {
    setShowReport(true);
  }
  const handleHideReport = () => {
    setShowReport(false);
  }

  const handleHide = () => {
    setShowConfirmation(false); 
  }
  const handleShowDeleteConfirmation = () => {
    setShowConfirmation(true); 
  }

  const handleDelete = async() => {
    const photo_id = photo_data.photo_id;
    const result = !photo_data.page_id ? await postDeleteAlbumPhoto({}, photo_id) : await postDeletePageAlbumPhoto(photo_id, photo_data.album_id, photo_data.page_id);
    
    if(result.status_code === 204) {
      toast.success("Photo deleted successfully!"); 
      handleHide(); 
      updatePhotoDate(); 
    } 
    else {
      toast.error("Error deleting photo!")
    }

  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  return (
    <div className="album-photo-item">
      <img
        src={photo_data.image}
        onClick={() => {
          handleShowPhoto(photo_data);
        }}
      />
      <div className="album-photo-item-options">
        <ReportModal 
          show={showReport}
          onHide={handleHideReport}
          type="photo"
          id={photo_data.photo_id}
        />
        <FontAwesomeIcon icon={faBars} onClick={handleMenu} />
        <div
          className={`album-photo-item-options-menu ${showMenu ? "show" : ""}`}
          ref={ref}
        >
          {
            isOwner ? null :
            <div className="album-photo-item-options-menu-item" onClick={handleShowReport}>Report</div>
          }
          { isOwner ? <a
            href={photo_data.image}
            download={String(photo_data.file_id)}
            target="_blank"
            className="album-photo-item-options-menu-item"
            onClick={handleDownload}
          >
            Download
          </a> : null}
          <div
            className="album-photo-item-options-menu-item"
            onClick={handleShare}
          >
            Share
          </div>
        { isOwner ? <div
          className="album-photo-item-options-menu-item"
          onClick={handleShowDeleteConfirmation}
        >
          Delete
         
        </div> : null}
        </div>
      </div>
      <div className="album-photo-item-info">
        <div>
          <span>{photo_data.like_count}</span>
          <FontAwesomeIcon icon={faThumbsUp} />
        </div>
        <div>
          <span>{photo_data.comment_count}</span>
          <FontAwesomeIcon icon={faComment} />
        </div>
      </div>
      <ShareUserContent
        show={showShare}
        data={photo_data}
        type={item_type}
        object_id={photo_data.photo_id}
        onHide={handleHideShare}
      />
      <ConfirmationModal 
          show={showConfirmation} 
          onConfirm={handleDelete} 
          onHide={handleHide}
          confirmation_message={"Are you sure you'd like to delete this image?"}
        />
    </div>
  );
};


const PhotosDialog = ({
  show,
  onHide,
  title,
  photo_data,
  isLoading = false,
  album_data = {},
  updatePhotoData, 
  photo_type="",
  isOwner=false
}) => {
  const [photo, setPhoto] = useState({});
  const [showPhoto, setShowPhoto] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const handleShowPhoto = (data, index) => {
    setPhoto(photo_data[index]);
    setPhotoIndex(index);
    setShowPhoto(true);
  };
  const handleHidePhoto = () => {
    setShowPhoto(false);
  };

  const handleNextPhoto = () => {
    const photo_len = photo_data.length;
    if (photoIndex < photo_len - 1) {

      setPhoto(photo_data[photoIndex + 1]);
      setPhotoIndex(photoIndex + 1);
    }
    if (photoIndex === photo_len - 1) {

      setPhoto(photo_data[0]);
      setPhotoIndex(0);
    }
  };
  const handlePreviousPhoto = () => {
    if (photoIndex > 0) {

      setPhoto(photo_data[photoIndex - 1]);
      setPhotoIndex(photoIndex - 1);
    }
    if (photoIndex === 0) {

      setPhoto(photo_data[photo_data.length - 1]);
      setPhotoIndex(photo_data.length - 1);
    }
  };
  console.log("PHOTOS DIALOG: ", isOwner)
  return (
    <Modal show={show} onHide={onHide} size={"lg"} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="album-photo-list">
          {isLoading === true ? (
            <div className="loading-icon-wrapper">
              <Bars stroke="#00BCD4" fill="#00BCD4" />
            </div>
          ) : null}
          {photo_data.length > 0 && !isLoading
            ? photo_data.map((val, index) => {
                return (
                  <PhotoItem
                    photo_data={val}
                    handleShowPhoto={(data) => {
                      handleShowPhoto(data, index);
                    }}
                    updatePhotoData={updatePhotoData}
                    item_type={photo_type ? photo_type : "photo"}
                    isOwnerPredetermined={isOwner}
                  />
                );
              })
            : null}
        </div>
      </Modal.Body>
      <VideoDisplay
        show={showPhoto}
        onHide={handleHidePhoto}
        video_data={photo}
        type="album_photo"
        subject_type="album_photo"
        subject_id={photo.photo_id}
        navHandles={{
          handleNext: handleNextPhoto,
          handlePrev: handlePreviousPhoto,
        }}
        owner_data={{
          owner_id: album_data.owner_id,
          owner_image: album_data.owner_image,
          owner_title: album_data.owner_title,
        }}
        itemIndex={photoIndex}
        totalNumber={photo_data.length}
        navButtons={true}
      />
    </Modal>
  );
};

export const SitepageAlbumItem = ({ album_data }) => {
  const { mutate, data, isLoading } = useGetSitePageAlbumPhotos(
    album_data.page_id,
    album_data.album_id,
    {},
  );

  const {id} = useParams(); 

  const {data: page_data} = useGetSitePage(id, {}); 
  console.log("Page_data photo item: ", page_data); 

  const [albumData, setAlbumData] = useState({});

  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };
  const handleHide = () => {
    setShow(false);
  };

  useEffect(() => {}, [show]);

  useEffect(() => {
    if (data && data.body && data.body.albumPhotos) {
      setAlbumData(() => ({ ...data.body }));
    }
  }, [data]);

  return (
    <>
      <div className="album-item" onClick={handleClick}>
        <img src={album_data.image} />
        <strong>{album_data.title}</strong>
        <p>
          {album_data.photo_count} photos - {album_data.like_count} likes
        </p>
      </div>
      <PhotosDialog
        show={show}
        onHide={handleHide}
        title={album_data.title}
        photo_data={albumData.albumPhotos ? albumData.albumPhotos : []}
        isLoading={isLoading}
        album_data={albumData.album}
        updatePhotoData={mutate}
        photo_type="sitepage_photo"
        isOwner={page_data.body.isOwner === 1 ? true : false}
      />
    </>
  );
};

export const GroupAlbumItem = ({ album_data }) => {
  return (
    <>
      <div className="album-item">
        <img src={album_data.image_normal} />
        <strong>{album_data.title}</strong>
        <p>
          {album_data.photo_count} photos - {album_data.like_count} likes
        </p>
      </div>
      {/* <PhotosDialog show={show} onHide={handleHide} title={album_data.title} photo_data={albumData.albumPhotos ? (albumData.albumPhotos) : ([])} isLoading={isLoading} album_data={albumData.album}/> */}
    </>
  );
};

export const DefaultAlbumItem = ({ album_data }) => {
  const { mutate, data, isLoading } = useGetAlbum(album_data.album_id, {});
  const [albumData, setAlbumData] = useState({});
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(true);
  };
  const handleHide = () => {
    setShow(false);
  };

  useEffect(() => {}, [show]);

  useEffect(() => {
    if (data && data.body) {
      setAlbumData(() => ({ ...data.body }));
    }
  }, [data]);

  return (
    <>
      <div className="album-item" onClick={handleClick}>
        <img src={album_data.image_normal} />
        <strong className="album-item-title">{album_data.title}</strong>
        <p className="album-item-numbers">
          {album_data.photo_count} photos - {album_data.like_count} likes
        </p>
      </div>
      <PhotosDialog
        show={show}
        onHide={handleHide}
        title={album_data.title}
        photo_data={albumData.albumPhotos ? albumData.albumPhotos : []}
        isLoading={isLoading}
        album_data={albumData.album}
        updatePhotoData={mutate}
      />
    </>
  );
};
