// Stories widget for profile page. 
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "rc-toastr";
import { useEffect, useState } from "react";
import { CreateStoryFileUploadModal } from "../../../pages/Profile/Stories/CreateStory";
import { postCreateSitestory, useGetSitestories } from "../../../utils/api/functions/sitestories";
import { hasStoryWidgetSeen } from "../../../utils/cookies";
import StoryView from "./StoryView";

const StoriesItem = ({story_data, onClick}) => {

    // Return null if no stories are available 
    if(story_data?.total_stories === 0) return null;

    const [active, setActive] = useState(false); 
    const [seen, setSeen] = useState(false); 
    useEffect(() => {
        if(story_data?.total_stories > 0){
            setActive(true)
            console.log("active set to true")
            if(hasStoryWidgetSeen(story_data.story_id)) {
                setSeen(true); 
                console.log("seen set to true")
            }
        }
    }, [story_data])
    
    const handleClick = () => {
        setSeen(true); 
        onClick(story_data.story_id);
    }

    return(
        <div className="stories-widget-item">
            <img 
                src={story_data.owner_image_icon}
                className={active && !seen ? "active" : ""} 
                style={{cursor: active ? "pointer" : ""}}   
                onClick={active ? handleClick : null}
            />
        </div>
    )
}

const AddStoryButton = ({onClick}) => {
    return(
        <div 
            className="add-story-button"
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faPlus}/>
        </div>
    )
}

const StoriesWidget = () => {
    const {data, error, mutate} = useGetSitestories(); 
    const [stories, setStories] = useState([]); 
    const [selectedStory, setSelectedStory] = useState(0); 
    const [showCreate, setShowCreate] = useState(false); 
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [progress, setProgress] = useState(0); 


    const {toast} = useToast(); 

    useEffect(() => {
        console.log("Sitestory data: ", data); 

        if(data && data.body && data.body.response) {
            setStories(() => [...data.body.response]);
        }

    }, [data])

    const handleStoryOpen = (story_id) => {
        setSelectedStory(story_id); 
    }

    const handleStoryClose = () => {
        setSelectedStory(0); 
        setProgress(0); 
    }

    const handleOpenCreateStory = () => {
        setShowCreate(true); 
    }
    const handleCloseCreateStory = () => {
        setShowCreate(false); 
    }
    const handleCreateStory = async(file) => {
        setIsSubmitting(true);
        const formData = new FormData(); 
        formData.append("filedata", file); 
        const res = await postCreateSitestory(formData,
            (event) => {
                setProgress(event); 
            }
        ); 
        if(res.status_code === 200) {
            toast.success("Story created!");
            
            handleCloseCreateStory();  
            setIsSubmitting(false); 
            setProgress(0); 
            mutate(); //Refresh stories list 
        }
        else {
            toast.error("Error creating story"); 
            setIsSubmitting(false);
            setProgress(0);
            mutate(); 
        }
        mutate(); 
    }

    return(
        <div className="stories-widget-container">
            <AddStoryButton onClick={handleOpenCreateStory}/>
            {stories.map((val, index) => {
                return <StoriesItem 
                    key={index} 
                    story_data={val} 
                    onClick={handleStoryOpen}
                />
            })}
            <StoryView story_id={selectedStory} show={selectedStory !== 0} onHide={handleStoryClose}/>
            <CreateStoryFileUploadModal 
                show={showCreate}
                onHide={handleCloseCreateStory}
                handleUploadFile={handleCreateStory}
                isSubmitting={isSubmitting}
                progress={progress}
            />
        </div>
    )
}


export default StoriesWidget; 